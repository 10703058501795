.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

@media only screen and (max-width: 1000px) {
  .mobileVisible {
    display: block !important;
  }

  .mobileHidden {
    display: none !important;
  }
}
