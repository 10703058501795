body {
  margin: 0;
  line-height: 1.6;
  color: #333;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

h1 {
  font-size: larger;
  font-weight: bold;
  margin-bottom: 10px;
}

.bgGray {
  background: #fafafa;
}

.cardBorder {
  border: 1px solid var(--color-border, rgba(0, 0, 0, 0.15));
}

/* import previews */
#importPreview {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
  background-color: transparent;
}

#importPreview td,
#importPreview th {
  border: 1px solid #ddd;
  padding: 8px;
  font-size: 13px;
}

#importPreview tbody tr:nth-child(even) {
  background-color: #f2f2f2 !important;
}

#importPreview tr:nth-child(even) {
  background-color: #f2f2f2;
}

#importPreview tbody tr:hover {
  background-color: #ddd;
}

#importPreview thead tr {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0f3759;
  color: white;
  font-size: 13px;
}

/* ant table formatting */
#manage-list .ant-table-thead .ant-table-cell,
#view-lists .ant-table-thead .ant-table-cell,
#view-jobs .ant-table-thead .ant-table-cell {
  background-color: white !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-top: none;
  border-left: none;
  border-right: none;
  color: rgba(0, 0, 0, 0.65);
}

#manage-list .ant-table-container,
#view-lists .ant-table-container,
#view-jobs .ant-table-container {
  border: none !important;
}

#manage-list .ant-table-tbody tr td:first-child,
#view-lists .ant-table-tbody tr td:first-child,
#view-jobs .ant-table-tbody tr td:first-child {
  border-left: 1px solid #f0f0f0 !important;
}

.tableActions {
  min-width: 170px;
  max-width: 170px;
}

/* hide My Fonts panel*/
#bp5-tab-title_undefined_font {
  display: none;
}

/* select plan: start */
.pricePerCard {
  --tw-bg-opacity: 1;
  transform: rotate(-37deg);
  padding-right: 132px;
  font-size: 14px !important;
  white-space: nowrap;
  margin-bottom: 15px;
  margin-left: -26px;
  font-weight: 300;
  color: rgb(154, 151, 151);
  margin-top: 20px;
  margin-bottom: -7px;
}

.selectPlan1 {
  background: linear-gradient(143deg, rgb(255, 255, 255) 45%, #ff7775 45%);
}

.selectPlan2 {
  background: linear-gradient(143deg, rgb(255, 255, 255) 45%, #fbff75 45%);
}

.selectPlan3 {
  background: linear-gradient(143deg, rgb(255, 255, 255) 45%, #89f94f 45%);
}

/* dashboard cards remaining */
.cardsRemaining {
  background: #5178d0;
  border-radius: 0.9em;
  -moz-border-radius: 0.9em;
  -webkit-border-radius: 0.9em;
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  line-height: 2.1em;
  margin-right: 2px;
  text-align: center;
  /* width: 2.1em;  */
  font-size: 10pt;
  padding-left: 8px;
  padding-right: 8px;
}

/* print job steps */
@media only screen and (max-width: 992px) {
  .steps-action {
    position: relative;
    z-index: 100;
    margin-top: 20px;
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 993px) {
  .steps-action {
    position: relative;
    z-index: 100;
    margin-top: 20px;
    margin-bottom: -47px;
  }
}

/* select-plan modal */
.benefitsTextBlock {
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  color: #ffffff;
  color: 16px;

  @media (min-width: 640px) {
    width: 50%;
    white-space: nowrap;
  }
  @media (max-width: 639px) {
    max-width: 300px;
  }
}

.balanced-text {
  text-wrap: balance;
}

.ant-list-header,
.ant-list-item {
  margin-bottom: 5px;
  border-block-end: 1px solid rgba(143, 141, 141, 0.2) !important;
}

.zoomer {
  zoom: 175%;
}

.ant-transfer-operation button {
  @apply text-PrimaryDarkBlue-100 rounded-md border border-credsPrimaryBlue-100 bg-PrimaryBgWhite-100 shadow-sm hover:!bg-PrimaryDarkBlue-100 hover:text-white m-3 px-4 inline-flex items-center;
}

.ant-transfer-list-content-item-remove {
  color: #ff4d4f !important;
}

/* Custom CSS to style the Switch when checked and unchecked */
.filter-switch,
.ant-switch:hover {
  background-color: inherit;
}
.filter-switch,
.ant-switch.ant-switch-checked {
  background-color: #4d90ce;
}
.filter-switch,
.ant-switch.ant-switch-checked:hover {
  background-color: 4d90ce;
}

.small-card-body > .ant-card-body {
  padding: 16px !important;
}

/* check-in */
#checkInFormControlWrapper form section div button.ant-switch {
  background-color: #dcdcdc;
}

#checkInFormControlWrapper form section div button.ant-switch-checked {
  background-color: #4d90ce;
}

#checkInFormControlWrapper form section .ant-upload-list-item {
  visibility: none;
}

#checkInFormControlWrapper section form .ant-upload-list-item-container,
#checkInFormControlWrapper section form .ant-upload-list-item,
#checkInFormControlWrapper section form .ant-upload-list-item-error {
  background-color: #f1f0f0 !important;
  border: 1px dashed gainsboro !important;
  border-radius: 8px;
}

#checkInFormControlWrapper section form .ant-upload-list-picture-card {
  width: 102px !important;
}
/* #checkInFormControlWrapper section form .ant-form-item-row {
  align-items: center !important;
} */

@media (max-width: 1180px) {
  #check-in-preview {
    flex-wrap: wrap;
  }
}

#checkInFormControlWrapper section form .ant-upload-list-item {
  background-color: #fff !important;
}

#cssOverrides {
  background-color: #fff;
}

.ant-picker-ok .ant-btn-primary {
  background-color: #1677ff;
}

/* css table documentation: start */
.css_tableContainer {
  display: table;
  width: 100% !important;
  border-collapse: collapse;
  margin-left: -10px;
  margin-bottom: 15px;
}

.css_tableContainerRow {
  display: table-row;
}

.css_tableContainerRow .css_tableContainerCell:first-child {
  white-space: nowrap;
  width: 1px;
}

.css_tableContainerRow .css_tableContainerCell:last-child {
  width: 100%;
}

.css_tableContainerCell {
  display: table-cell;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.css_tableContainerHeader {
  background-color: #f2f2f2;
}

.css_tableSectionHeader {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  color: dodgerblue;
}
/* css table documentation: end */

.ql-size-huge {
  font-size: 2.5rem !important;
}

.ql-size-large {
  font-size: 2rem !important;
}

.ql-size-small {
  font-size: 0.75rem !important;
}

#k_checkInPanelHeaderMessage p {
  font-size: 1.5rem !important;
}

.ant-btn-primary.ant-btn-color-primary.ant-btn-variant-solid {
  background-color: #1d8ef1;
}
