.ant-table-filter-dropdown-btns .ant-btn-primary{
    background-color: #1890ff;
}

.adminWrapper {
    background-color: #fff;
    margin: 0px 10px 10px 10px;
    padding: 20px;
    border-radius: 8px;
}

.adminHeader {
    font-size: 18pt;
    margin: 5px 5px 15px 5px;
}

.adminContainerRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 30px;
}

.adminContainer {
    padding: 5px 15px 5px 15px;
    background-color: #f0f2f5;
    border: 1px solid transparent;
    border-radius: 12px;
    margin: 20px 0px 6px 0;
    flex: 1;
    flex-wrap: wrap;
    min-width: 500px;
}

.adminContainerHeader {
    font-size: 11pt;
    font-weight: 700;
    margin-right: 5px;
    margin-top: 10px;
    margin-left: 15px;
    margin-bottom: -10px;
}

DIV#adminAccountListing .ant-table-tbody,
DIV#adminAccountJobListing .ant-table-tbody,
DIV#adminAccountTransactions .ant-table-tbody,
DIV#adminAccountUsers .ant-table-tbody,
DIV#adminAccountAddresses .ant-table-tbody,
DIV#adminAccountTemplates .ant-table-tbody,
DIV#adminAccountUsers .ant-table-tbody,
DIV#adminAccountShipments .ant-table-tbody,
DIV#adminJobDetails .ant-table-tbody {
    padding: 0 !important;
}

DIV#adminAccountListing .ant-table-tbody > tr > td,
DIV#adminAccountJobListing .ant-table-tbody > tr > td,
DIV#adminAccountTransactions .ant-table-tbody > tr > td,
DIV#adminAccountUsers .ant-table-tbody > tr > td,
DIV#adminAccountAddresses .ant-table-tbody > tr > td,
DIV#adminAccountTemplates .ant-table-tbody > tr > td,
DIV#adminAccountUsers .ant-table-tbody > tr > td,
DIV#adminAccountShipments .ant-table-tbody > tr > td,
DIV#adminJobDetails .ant-table-tbody > tr > td {
    padding: 0 !important;
}

DIV#adminAccountListing .ant-table-thead .ant-table-cell,
DIV#adminAccountJobListing .ant-table-thead .ant-table-cell,
DIV#adminAccountTransactions .ant-table-thead .ant-table-cell,
DIV#adminAccountUsers .ant-table-thead .ant-table-cell,
DIV#adminAccountAddresses .ant-table-thead .ant-table-cell,
DIV#adminAccountTemplates .ant-table-thead .ant-table-cell,
DIV#adminAccountUsers .ant-table-thead .ant-table-cell,
DIV#adminAccountShipments .ant-table-thead .ant-table-cell,
DIV#adminJobDetails .ant-table-thead .ant-table-cell {
    background-color: #3d4a52 !important;
    color: #fff !important
}

DIV#adminAccountListing,
DIV#adminAccountJobListing,
DIV#adminAccountTransactions,
DIV#adminAccountUsers,
DIV#adminAccountAddresses,
DIV#adminAccountTemplates,
DIV#adminAccountUsers,
DIV#adminAccountShipments,
DIV#adminJobDetails,
DIV#adminAccountTransactions {
    min-width: 789px;
}

.adminTableRow tr:hover > td {
    background-color: #A7F234 !important;
    color: #000000 !important;
    cursor: pointer;
}