.ant-layout-content {
  top: 160px;
  left: 20px;
  right: 20px;
  z-index: 999;
  font-size: 16px;
  padding: 20px;
}

.scroll-container {
  max-height: 400px; /* Adjust this value as needed */
  overflow-y: auto;
  padding-right: 2rem;
}

/* template previews:start */
.templateThumbnailPreviewWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: visible;
  z-index: 20;
  height: 100%;
  transition-duration: 0.6s;
  transition-property: -webkit-transform, transform, -webkit-transform;
}

.verticalCardPreviewGrid {
  height: 167.022%;
  width: 75%;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  margin: auto;
  overflow: hidden;
  position: relative;
}

.horizontalCardPreviewGrid {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  margin: auto;
  overflow: hidden;
  position: relative;
  max-height: 153px;
}

.verticalCardPreviewRow {
  height: 50%;
  width: 50%;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: -32px;
  margin-left: -32px;
  overflow: hidden;
}

.horizontalCardPreviewRow {
  height: 50%;
  width: 50%;
  border-radius: 8px;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: -32px;
  margin-left: -32px;
  overflow: hidden;
  max-height: 153px;
}
.templateThumbnailBox {
  display: flex;
  flex-direction: column;
}

.templateThumbnailContainerGrid {
  background-color: var(--COXgsg);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 75%;
  position: relative;
  width: 100%;
  display: block;
}

.templateThumbnailContainerRow {
  background-color: var(--COXgsg);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  display: block;
}

.templateThumbnailWrapperGrid {
  padding-bottom: 0;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 16px;
  top: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: #e5e4e2;
}

.templateThumbnailWrapperRow {
  padding-bottom: 0;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 16px;
  top: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: #e5e4e2;
  margin-top: -16px;
  margin-left: -20px;
}

.templateThumbnail {
  border: 1px solid silver;
  height: 100%;
  position: absolute;
  width: 100%;
  overflow: clip;
  cursor: pointer;
}

.block100w100hAbsolute {
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
}

.block100w100hRelative {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}
/* template previews:end */
