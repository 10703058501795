.header .ant-menu {
  border: 0;
  background: none !important;
}

.ant-menu.ant-menu-horizontal{
  color: #333;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected{
  color: #1D8EF1 !important;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected{
  color: #1D8EF1 !important;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item-selected::after{
  border-bottom-width: 2px;
  border-bottom-color: #1D8EF1 !important;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu-selected::after{
  color: #1D8EF1 !important;
  border-bottom-width: 2px;
  border-bottom-color: #1D8EF1;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-submenu:hover::after{
  border-bottom-width: 2px;
  border-bottom-color: #1D8EF1;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after{
  border-bottom-width: 2px;
  border-bottom-color: #1D8EF1;
}

.ant-menu-light .ant-menu-submenu-selected >.ant-menu-submenu-title{
  color: #1D8EF1 !important;
}

.custom-drawer-width > .ant-drawer-content-wrapper   {
  width: 500px !important;
}

.ant-drawer-content-wrapper {
  width: 250px !important;
}

:focus {
  outline: none !important
}

a:hover {
  cursor: pointer !important;
  text-decoration: none !important;
}
