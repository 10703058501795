.ant-layout-header {
  /* position: fixed; */
  left: 0;
  right: 0;
  z-index: 1000;
}

.site_header_wrapper {
  min-height: 70px;
  max-height: 70px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}

img.logo {
  position: absolute;
  top: 20px;
  left: 30px;
  background-color: transparent;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
}

.header .ant-anchor-link {
  padding: 0 20px;
  line-height: 1.4;
  position: relative;
}

.header .ant-anchor-link:before,
.header .ant-anchor-link:after {
  content: '';
  background: #1890ff;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-anchor-link:after {
  left: auto;
  right: 30%;
}

.header .ant-anchor-link.ant-anchor-link-active:before,
.header .ant-anchor-link:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-anchor-link.ant-anchor-link-active:after,
.header .ant-anchor-link:hover:after {
  right: 5%;
  opacity: 1;
}