.container-fluid {
  margin: 0 auto;
  padding: 0 15px;
}

.ant-layout {
  background: #fff !important;
}

.mainLayout {
  display: flex;
  flex-direction: column;
  background-color: lavender;
}

.mainLayout .ant-layout-header {
  background: #fff;
  padding: 0;
  height: auto;
  line-height: 1.6;
}

.ant-menu-inline-collapsed >.ant-menu-item{
  width: 100%;
}
