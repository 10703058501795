.breadcrumbWrapper {
  background-color: rgb(15, 55, 89);
  margin-top: 10px;
  padding: 20px 15px 15px 15px;
}

.breadcrumbActive {
  color: #a7f234;
  margin-left: 0px;
  margin-right: 4px;
}

.breadcrumbInactive {
  color: #fff;
  margin-left: 0px;
  margin-right: 4px;
}

.breadcrumbIcon {
  margin-top: 4px;
}

.ant-breadcrumb-separator {
  color: #fff !important;
}
