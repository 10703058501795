.ant-layout-footer {
  background: rgb(15, 55, 89) !important;
  color: #fff !important;
  text-align: center;
  bottom: 0;
  width: 100%;
  padding: 18px 50px;
}

.footer .fas {
  color: #1890ff;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fff;
}

.footer .goTop:hover {
  opacity: 0.7;
}

@media only screen and (min-width: 1000px) {
  #footer_wrapper {
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding:  0px;
    margin-bottom: -15px;
    margin-top: -5px;
  }
  #footer_wrapper > div {
    cursor: pointer;
    margin: 0px 10px;
  }
  #footer_terms {
    background-color: transparent;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    }
  #footer_privacy {
    background-color: transparent;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    }
  #footer_cookie {
    background-color: transparent;
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
    }
  #footer_shipping {
    background-color: transparent;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    }
  #footer_contact {
    background-color: transparent;
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 1;
    grid-row-end: 2;
    }
}

@media only screen and (min-width: 500px) and (max-width: 999px) {
  #footer_wrapper {
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px;
  }
  #footer_wrapper > div {
    cursor: pointer;
    margin: 5px 10px;
  }
  #footer_terms {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    }
  #footer_privacy {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    }
  #footer_cookie {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    }
  #footer_shipping {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
    }
  #footer_contact {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    }
}

@media only screen and (min-width: 1px) and (max-width: 499px) {
  #footer_wrapper {
    background-color: transparent;
    display: grid;
    grid-template-columns: 1fr;
    padding: 10px;
  }
  #footer_wrapper > div {
    cursor: pointer;
    margin: 10px 10px;
  }
  #footer_terms {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
    }
  #footer_privacy {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
    }
  #footer_cookie {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
    }
  #footer_shipping {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
    }
  #footer_contact {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;
    }
}

